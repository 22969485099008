<template>
  <v-card
  max-width="100%"
  outlined
  >
  <v-form 
    @submit.prevent="save_warehouse" 
    ref="save_warehouse" 
    :disabled="get_plan && get_plan.id === 1"
    >
    <v-card-title>
      Fulfillment & products
    </v-card-title>
    <!-- <v-card-subtitle>
      Order fulfillment
    </v-card-subtitle> -->
    <v-alert
      type="info"
      class="mx-4"
      v-if="get_plan && get_plan.id === 1"
      >
      These settings can't be edited on your current plan.
    </v-alert>
    <v-row>
      <v-col cols="12" md="6">
        <v-subheader>Parcels per round
          <ToolTip>
            The amount of orders picked simultaneously, ie how many boxes you have on your cart
          </ToolTip>
        </v-subheader>
          <v-slider
            v-model="order_bucket_size"
            thumb-label="always"
            class="mx-4 my-6"
            :max="20"
            :min="1"
            @change="save_disabled = false"
            :rules="[
              v => v <= get_plan.max_order_bucket_size || `Max ${get_plan.max_order_bucket_size} parcels per round. Upgrade plan to get more.`
            ]"
          ></v-slider>
      </v-col>
      <v-col cols="12" md="6">
        <v-subheader>
          Request count when item inventory drop below
          <ToolTip>
            When an article drop below this amount according to Shopify, we will request the picker to double check by counting the shelf. If it's inaccurate, the inventory in Shopify will be updated with what's actually on the shelf and an alert will be created for the admin.
          </ToolTip>
          </v-subheader>
          <v-slider
            v-model="check_inventory_threshold"
            thumb-label="always"
            class="mx-4 my-6"
            :max="20"
            :min="0"
            @change="save_disabled = false"
          ></v-slider>
      </v-col>
      <v-col cols="12" md="6">
        <v-subheader>
          Redo inventory count after (days)
          <ToolTip>
            If an article has been counted once, this is for how long we will consider that count accurate before we are asking for another re-count (in the event the article quantity is below the threshold)
          </ToolTip>
          </v-subheader>
          <v-slider
            v-model="days_valid_inventory_count"
            thumb-label="always"
            class="mx-4 my-6"
            :max="365"
            :min="1"
            @change="save_disabled = false"
          ></v-slider>
      </v-col>
      <v-col cols="12" md="6">
        <v-subheader>Auto sync products every (hours)
          <ToolTip>
            Syncing often (if you have a lot of products) slows down opening {{ $appName }}. Note: Everytime a product gets picked, that product will automatically sync!
          </ToolTip>
          <v-btn 
            x-small
            outlined
            class="ml-2"
            @click="sync_products"
            :loading="loading_products"
            >
            Sync now
          </v-btn>
        </v-subheader>
          <v-slider
            v-model="products_refresh_interval"
            thumb-label="always"
            class="mx-4 my-6"
            :max="100"
            :min="1"
            @change="save_disabled = false"
          ></v-slider>
      </v-col>
      <v-col cols="6">
            <v-switch
              v-model="require_item_scanning"
              class="mx-4"
              @change="save_disabled = false"
              :disabled="!get_plan || !get_plan.allow_product_scanning"
            >
            <template v-slot:label>
              Scan product bar codes
              <ToolTip>
                When an item has a barcode, require it to be scanned when packing parcels to ensure the correct product is being shipped.
              </ToolTip>
            </template>
            </v-switch>
      </v-col>
      <v-col cols="6">
            <v-switch
              v-model="require_shelf_scanning"
              class="mx-4"
              @change="save_disabled = false"
              :disabled="!get_plan || !get_plan.allow_shelf_scanning"
            >
            <template v-slot:label>
              Scan shelf QR codes
              <ToolTip>
                Print QR codes for all shelf positions and require these to be scanned when items are being picked to ensure the right product gets picked.
              </ToolTip>
            </template>
            </v-switch>
      </v-col>
      <v-col cols="6">
            <v-switch
              v-model="require_fulfillment_photos"
              class="mx-4"
              @change="save_disabled = false"
            >
            <!-- :disabled="!get_plan || !get_plan.allow_product_scanning" -->
            <template v-slot:label>
              Require fulfillment photos
              <ToolTip>
                Ask staff to take a photo of items before fulfilling, and save the photo on the parcel for future reference and cross checks.
              </ToolTip>
            </template>
            </v-switch>
      </v-col>
      <v-col cols="6">
            <v-switch
              v-model="group_orders_by_similarity"
              class="mx-4"
              @change="save_disabled = false"
            >
            <!-- :disabled="!get_plan || !get_plan.allow_product_scanning" -->
            <template v-slot:label>
              Group orders by similarity
              <ToolTip>
                Automatically select the {{ order_bucket_size }} most similar orders to make picking most efficient. If this is not selected, orders will be picked oldest to newest.
              </ToolTip>
            </template>
            </v-switch>
      </v-col>
    </v-row>
    <!-- <v-divider class="mx-4"></v-divider> -->

      <v-card-actions>
        <v-btn 
          color="success"
          type="submit"
          class="px-12"
          :block="$vuetify.breakpoint.xsOnly"
          :disabled="save_disabled"
          :loading="save_loading"
          >
          Save
        </v-btn>
      </v-card-actions>
  </v-form>
</v-card>
</template>

<script>

import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import ToolTip from '@/components/ToolTip.vue'

export default {
  components: {
    ToolTip
    
  },
  data(){
    return {
      save_disabled: true,
      save_loading: false,
      original_company: { ...this.$store.state.warehouse.company }
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company, 
      loading_products: state => state.product.loading_products,
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
    }),
    ...mapFields([
      'warehouse.company.products_refresh_interval',
      'warehouse.company.order_bucket_size',
      'warehouse.company.days_valid_inventory_count',
      'warehouse.company.check_inventory_threshold',
      'warehouse.company.require_item_scanning',
      'warehouse.company.require_shelf_scanning',
      'warehouse.company.require_fulfillment_photos',
      'warehouse.company.group_orders_by_similarity',
      ]),
  },
  methods: {
    async save_warehouse(){
      if(!this.$refs.save_warehouse.validate()) return
      this.save_loading = true
      const keys = []
      for(const key in this.company){
        if(this.company[key] !== this.original_company[key]) keys.push(key)
      }
      await this.$store.dispatch('warehouse/save_company', keys)
      this.save_loading = false
      this.save_disabled = true
    },
    async sync_products(){
      await this.$store.dispatch('product/get_products', { company_uid: this.company.id, refresh: true})
    }
  },
  mounted(){
  }
}
</script>